<!--三农服务-->
<template>
    <div class="server">
        <div class="sv-1f w-1400">
            <el-row :gutter="30">
                <el-col :span="14">
                    <div class="sv-baneer">
                        <div class="bigpic fl" >
                            <el-carousel height="420px" indicator-position="none" arrow="never" ref="cars" @change="carsChange">
                                <el-carousel-item v-for="item in ZHXXtopdata" :key="item.id">
                                    <el-image style="width:100%;height:100%" fit="cover" :src="item.photoUrls" @click="goDetail(item.id,'综合信息',JSON.stringify(ZHXXparams))"></el-image>
                                </el-carousel-item>
                            </el-carousel> 
                        </div>
                        <div class="sm-pic fr">
                            <ul class=" ">
                                <li class="sm-item" v-for="(item,k) in ZHXXtopdata" :key="item.id+'top'" :class="{act:k=== actK}">
                                    <el-image style="width:100%;height:100%" :src="item.photoUrls" @click="carsClick(k)"></el-image>
                                </li>
                            </ul>
                        </div>
                    </div>
                </el-col>
                <el-col :span="10">
                    <div class="sc-r">
                        <div class="sv-h4 clearfix">
                            <div class="fl">
                                <i class="arrow-r"></i> 综合信息
                            </div>
                            <div class="more fr"><router-link to="/List?infoType=259033703653445&type=综合信息"> 更多 <i class="el-icon-arrow-right"></i></router-link></div>
                        </div>
                        <ul class="sv-list">
                            <li class="sv-item" v-for="item in ZHXXdata" :key="item.id" @click="goDetail(item.id,'综合信息',JSON.stringify(ZHXXparams))">
                                <div class="ellipsis sv-title">{{item.title}}</div>
                                <div>{{item.releaseDate&&item.releaseDate.substr(0,10)}}</div>
                            </li>
                        </ul>
                    </div>
                    
                </el-col>
            </el-row>
        </div>
        <!--三农服务-->
        <div class="sannong w-1400">
            <div class="t2 ">
                三农服务
            </div>
            <div class="sannong-mian">
                <div class="sannong-subtitle">
                    <div class="sub-list">
                        <span :class="{act:SNFWparams.filter.secondInfoType == '农村'}" @click="changeSNFW('农村')">农村</span>
                        <span :class="{act:SNFWparams.filter.secondInfoType == '农民'}" @click="changeSNFW('农民')">农民</span>
                        <span :class="{act:SNFWparams.filter.secondInfoType == '农业'}" @click="changeSNFW('农业')">农业</span>
                    </div>
                    <div class="more"><router-link to="/List?infoType=249253253980229&type=三农服务"> 更多<i class="el-icon-arrow-right"></i></router-link></div>
                </div>
                <div  class="sannong-mian">
                    <el-row :gutter="25">
                        <el-col :span="6" v-for="item in SNFWdata" :key="item.id">
                            <div class="sannong-item" :class="{act:item===1}" >
                                <router-link :to="`/detail?id=${item.id}&type=三农服务&oParams=${JSON.stringify(SNFWparams)}`">
                                    <div class="sannong-pic ">
                                        <el-image style="width:100%;height:100%" :src="item.photoUrls"></el-image>
                                    </div>
                                    <div class="sannongtitle ellipsis">{{item.title}}</div>
                                </router-link>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <!--农村新貌-->
        <div class="nongcun w-1400">
            <div class="t2"><span>农村新貌</span>  <span @click="$router.push('/List?infoType=259034552643653&type=农村新貌')">更多</span></div>
            <div class="nc-main">
                <div class="nc-box">
                    <router-link :to="`/detail?id=${calList[(Math.abs(transNum/245)+2)].id}&type=农村新貌&oParams=${JSON.stringify(NCXMparams)}`">
                        <el-image :src="calList[(Math.abs(transNum/245)+2)].photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                    </router-link>
                </div>
                <div class="nc-name">{{calList[(Math.abs(transNum/245)+2)].title}}</div>
                <div class="car-box">
                    <div class="cal-small">
                        <ul class="cal-small-list clearfix" :class="{istrans}" :style="`width:${245*calList.length*2}px;transform:translateX(${transNum}px)`">
                            <li class="cal-smal-item fl" :class="{isSlace:(Math.abs(transNum/245)+2 === index)}" v-for="(item,index) in calList" :key="index +'cal'">
                                <div class="star-pic">
                                    <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                </div>
                                <div class="ceng" v-show="!(Math.abs(transNum/245)+2 === index)"></div>
                            </li>
                        </ul>
                        <div class="cal-btn prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
                        <div class="cal-btn next" @click="next"><i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <!--农业天地-->
        <div class="nongye w-1400">
            <div class="t2">农业天地 </div>
            <div class="nongye-main">
                <el-row :gutter="50">
                    <el-col :span="8">
                        <div class="inner-box">
                            <div class="ny-header">
                                <div class="ny-h5 clearfix">
                                    <div class="fl">农技推广</div>
                                    <div class="fr"><router-link to="/List?infoType=257025987149893&type=农业天地"> 更多<i class="el-icon-arrow-right"></i></router-link></div>
                                </div>
                                <div class="clearfix"> 
                                    <div class="ny-pic fl">
                                        <el-image :src="NJTGdata[0].photoUrls" style="width:100%;height:100%" fit="cover" @click="goDetail(NJTGdata[0].id,'农业天地',JSON.stringify(NJTGparams))"></el-image>
                                    </div>
                                    <div class="ny-info fr">
                                        <div>{{NJTGdata[0].title}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ny-list">
                                <ul >
                                    <li class="ny-item " v-for="item in NJTGdata" :key="item.id" @click="goDetail(item.id,'农业天地',JSON.stringify(NJTGparams))">
                                        <div class="ellipsis ny-h4">{{item.title}}</div>
                                        <div>{{item.createdTime&&item.createdTime.substr(0,10)}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="inner-box">
                            <div class="ny-header">
                                <div class="ny-h5 clearfix">
                                    <div class="fl">办事指南</div>
                                    <div class="fr" ><router-link to="/List?infoType=257025987149893&type=农业天地">更多<i class="el-icon-arrow-right"></i></router-link></div>
                                </div>
                                <div class="clearfix"> 
                                    <div class="ny-pic fl">
                                        <el-image :src="BSZNdata[0].photoUrls" style="width:100%;height:100%" fit="cover"  @click="goDetail(BSZNdata[0].id,'农业天地',JSON.stringify(BSZNparams))"></el-image>
                                    </div>
                                    <div class="ny-info fr">
                                        <div>{{BSZNdata[0].title}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ny-list">
                                <ul >
                                    <li class="ny-item " v-for="item in BSZNdata" :key="item.id" @click="goDetail(item.id,'农业天地',JSON.stringify(BSZNparams))">
                                        <div class="ellipsis ny-h4">{{item.title}}</div>
                                        <div>{{item.createdTime&&item.createdTime.substr(0,10)}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="inner-box">
                            <div class="ny-header">
                                <div class="ny-h5 clearfix">
                                    <div class="fl">农村经济</div>
                                    <div class="fr"><router-link to="/List?infoType=257025987149893&type=农业天地"> 更多<i class="el-icon-arrow-right"></i></router-link></div>
                                </div>
                                <div class="clearfix"> 
                                    <div class="ny-pic fl">
                                        <el-image :src="NCJJdata[0].photoUrls" style="width:100%;height:100%" fit="cover" @click="goDetail(NCJJdata[0].id,'农业天地',JSON.stringify(NCJJparams))"></el-image>
                                    </div>
                                    <div class="ny-info fr">
                                        <div>{{NCJJdata[0].title}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ny-list">
                                <ul >
                                    <li class="ny-item " v-for="item in NCJJdata" :key="item.id" @click="goDetail(item.id,'农业天地',JSON.stringify(NCJJparams))">
                                        <div class="ellipsis ny-h4">{{item.title}}</div>
                                        <div>{{item.createdTime&&item.createdTime.substr(0,10)}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import {GetList} from '@/api/other.js'
export default {
    data(){
        return{
            transNum:0,
            calList:[{},{},{},{},{}],
            istrans:true,
            actimg:'',
            ZHXXparams:{//综合信息
                currentPage: 1,
                filter: {infoType:259033703653445},
                pageSize: 10,
            },
            ZHXXact:'',
            ZHXXdata:[],
            ZHXXtopdata:[],
            SNFWparams:{//三农服务
                currentPage: 1,
                filter: {infoType:249253253980229,secondInfoType:'农村'},
                pageSize: 8,
            },
            SNFWdata:[],
            NCXMparams:{//农村新貌
                currentPage: 1,
                filter: {infoType:259034552643653,},
                pageSize: 8,
            },
            NCXMdata:[],
            //农业天地
            NJTGparams:{//农技推广
                currentPage: 1,
                filter: {infoType:257025987149893,secondInfoType:'农技推广'},
                pageSize: 11,
            },
            NJTGdata:[{}],
            NCJJparams:{//农村经济
                currentPage: 1,
                filter: {infoType:257025987149893,secondInfoType:'农村经济'},
                pageSize: 11,
            },
            NCJJdata:[{}],
            BSZNparams:{//办事指南
                currentPage: 1,
                filter: {infoType:257025987149893,secondInfoType:'办事指南'},
                pageSize: 11,
            },
            BSZNdata:[{}],
            actK:0,
        }
    },
    methods:{
        next(){
            console.log(this.transNum,-this.calList.length*245)
            if(this.transNum === -this.calList.length*245/2){
                this.transNum = 0
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum -= 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum -= 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        prev(){
            if(this.transNum === 0){
                this.transNum = -this.calList.length*245/2
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum += 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum += 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        getZHXXdata(){
            GetList(this.ZHXXparams).then(res=>{
                this.ZHXXdata = res.data.data.list;
                this.ZHXXtopdata = res.data.data.list.filter((item,index)=>index<4);
                this.ZHXXact = this.ZHXXtopdata[0].photoUrls;
            })
        },
        getSNFWdata(){
            GetList(this.SNFWparams).then(res=>{
                this.SNFWdata = res.data.data.list;
            })
        },
        changeSNFW(a){
            this.SNFWparams.filter.secondInfoType = a;
            this.getSNFWdata();
        },
        getNCXMdata(){
            GetList(this.NCXMparams).then(res=>{
                this.calList = res.data.data.list;
                this.calList =this.calList.concat(this.calList)
            })
        },
        getNJTGdata(){
            GetList(this.NJTGparams).then(res=>{
                this.NJTGdata = res.data.data.list;
            })
        },
        getBSZNdata(){
            GetList(this.BSZNparams).then(res=>{
                this.BSZNdata = res.data.data.list;
            })
        },
        getNCJJdata(){
            GetList(this.NCJJparams).then(res=>{
                this.NCJJdata = res.data.data.list;
            })
        },
        goDetail(id,type,oParams){
            this.$router.push({path:'/detail',query:{id,type,oParams}})
        },
        carsChange(k){
            this.actK = k;
        },
        carsClick(k){
            this.$refs['cars'].setActiveItem(k);
        }
    },
    mounted(){
        this.getZHXXdata();
        this.getSNFWdata();
        this.getNCXMdata();
        this.getNJTGdata();
        this.getBSZNdata();
        this.getNCJJdata();
    }
}
</script>
<style scoped>
.nongcun .t2{
    display: flex;
    justify-content: space-between;
}
.nongcun .t2>span:last-child{
    font-size: 16px;
    font-weight: 400;
}
.nongcun .t2>span:last-child:hover{
    color: #666;
    cursor: pointer;
}
.ny-item .ny-h4{
    width: calc(100% - 100px)
}
.ny-item:hover{
    color: #0e69c2;
    cursor: pointer;
}
.ny-list{
    padding: 10px;
    height: 308px;

}
.ny-list>ul>li:first-child{
    display: none;
}
.ny-item{
    line-height: 30px;
    font-size: 16px;
    color: #666;
    display: flex;
    justify-content: space-between;
}
.ny-info div{
    display: table-cell;
    vertical-align: middle;
    line-height: 1.4;
    font-size: 16px;
    color: #666;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
}
.ny-info{
    width: calc(100% - 228px);
    height: 116px;
    display: table;
}
.ny-pic{
    height: 116px;
    width: 210px;
}
.ny-h5{
    line-height: 36px;
    font-size: 16px;
    font-weight: 600;
    color: #666;
}
.inner-box{
    border: 1px solid #E4E7ED;
}
.ny-header{
    padding:10px;
    border-bottom: 1px solid #E4E7ED;
}
.car-box{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 175px;
    padding-top: 8px;
    width: 100%;
}
.star-pic{
    height: 142.5px;
}
.nc-name{
    padding: 10px 20px;
    font-size: 20px;
    background: #3aceda;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
}
.nc-box{
    height: 100%;
}
.nc-main{
    height: 700px;
    overflow: hidden;
    position: relative;
}
.isSlace{
    transform: scale(1.2);
    border: 2px solid #fff;
}
.isSlace .star-info{
    background: #f37b1c;
    color: #fff;
}
.istrans{
    transition: transform 1.2s;
}
.prev{
    left: 0;
}
.next{
    right: 0;
}
.cal-btn{
    width: 50px;
    height: 80px;
    line-height: 80px;
    font-size: 50px;
    font: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
    z-index: 20;
}
.cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.ceng{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
}
.cal-smal-item{
    width: 220px;
    height: 142.5px;
    overflow: hidden;
    margin-right: 25px;
    position: relative;
    transition: transform 1.2s;
    box-sizing: border-box;
}
.cal-small{
    height: 160px;
    padding-top:8px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.t2{
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
}
.sannongtitle{
    line-height: 32px;
    font-size: 16px;
    text-align: center;
}
.sannong-pic{
    height: 240px;
}
.sannong-item:hover{
    background: #efefef;
}
.sannong-item.act{
    border: 1px solid #E4E7ED;
}
.sannong-item{
    padding: 8px;
    height: 290px;
    margin-top: 10px;
}
.sannong-subtitle .more{
    line-height: 38px;
}
.sub-list span~span{
    border-left: 1px  solid #E4E7ED;
}
.sub-list span:hover,.sub-list span.act{
    background: #7eba0d;
    color: #fff;
}
.sub-list span{
    padding: 0 20px;
    cursor: pointer;
}
.sub-list{
    padding: 10px;
}
.sannong-subtitle{
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E4E7ED;
}
.sv-item:hover{
    color:#0e69c2;
    cursor: pointer;
}
.sv-item{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #555;
    line-height: 35px;
    border-bottom: 1px solid #eee;
}
.sv-item .sv-title {
    width: calc(100% - 100px);
    /* font-weight: 600 */
}
.sv-list{
    margin-top: 10px;
}
.sv-h4 .arrow-r{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../assets/img/right2.png) no-repeat;
    background-size: 100% 100%;
    transform: translateY(2px);
}
.sv-h4{
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    border-bottom: 1px solid #E4E7ED;
}
.sc-r{
    border: 1px solid #E4E7ED;
    padding: 9px 10px;
    height: 420px;
}
.sm-item+.sm-item{
    margin-top: 10px;  
}
.sm-item.act{
    border: 1px solid #0e6ac1;
}
.sm-item{
    height: 97.5px;
    box-sizing: border-box;
    padding: 5px;
}
.sm-pic{
    width: calc(100% - 560px);
    height: 420px;
    overflow: hidden;
}
.bigpic{
    width: 540px;
    height: 420px;
}
.server{
    font-family: '微软雅黑';
}
</style>